<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    @open="handleOpen"
    @close="resetForm"
  >
    <el-form
      :model="formData"
      label-width="120px"
      ref="form"
      label-position="top"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item
            style="width:260px"
            label="手机号："
            :rules="[
              $formRules.required(),
              $formRules.tel,
              originalCellPhone !== formData.cellphone ? checkTel : {}
            ]"
            prop="cellphone"
          >
            <el-input
              maxlength="11"
              v-model="formData.cellphone"
              placeholder="请输入"
              :disabled="!!formData.id && formDisabled.cellphone"
              @click.native="handleInputClick('cellphone')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            style="width:260px"
            label="用户姓名："
            :rules="[$formRules.required()]"
            prop="name"
          >
            <el-input
              v-model="formData.name"
              placeholder="请输入"
              :disabled="!!formData.id && formDisabled.name"
              @click.native="handleInputClick('name')"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        label="用户角色："
        :rules="[$formRules.required()]"
        prop="roleType"
      >
        <el-radio-group v-model="formData.roleType" class="f-radio-oval">
          <el-radio
            v-for="(item, index) in roleTypeList"
            :label="item.id"
            :key="index"
            border
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="formData.roleType == 2"
        label="设置权限："
        :rules="[$formRules.required()]"
      >
        <div
          class="tree-box"
          :class="{
            disabled: $route.path === '/distribution/userManage/admin'
          }"
        >
          <el-tree
            ref="tree"
            :default-expand-all="
              $route.path === '/distribution/userManage/admin'
            "
            :data="menuTree"
            show-checkbox
            node-key="id"
            :expand-on-click-node="false"
            check-on-click-node
            :props="defaultProps"
          >
          </el-tree>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="$emit('update:visible', false)">取 消</el-button>
      <el-button type="primary" @click="submitForm">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  editEnterpriseUser,
  createEnterpriseUser,
  getMenuTree,
  checkCellphone
} from "@/api/setting";
const formData = {
  cellphone: "",
  menuIdList: [],
  id: null,
  name: "",
  roleType: 1
};
// 检验电话
const checkTel = async (rule, value, callback) => {
  if (value) {
    const data = await checkCellphone(
      {
        cellphone: value
      },
      false
    );
    if (data) {
      callback();
    } else {
      callback(new Error("该手机号已存在，请重新输入手机号"));
    }
  }
};
export default {
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      checkedMenuList: [],
      menuTree: [],
      // 菜单树配置选项
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      roleTypeList: [
        {
          id: 1,
          name: "超级管理员"
        },
        {
          id: 2,
          name: "管理员"
        }
      ],
      // 编辑管理员时  默认不可编辑用户信息，点击进入编辑状态
      formDisabled: {
        cellphone: true,
        name: true
      },
      formData: this.$deepClone(formData),
      // 编辑时原本的电话号码 用于判断如果电话号码跟原本相同 则不校验
      originalCellPhone: "",
      // 电话校验
      checkTel: { validator: checkTel, trigger: "blur" }
    };
  },
  methods: {
    // 编辑时点击输入框 解除禁用状态
    handleInputClick(key) {
      this.formDisabled[key] = false;
    },
    // 打开组件时设置数据
    handleOpen() {
      console.log(this.$route);
      if (!this.menuTree.length) {
        this.getMenuTree();
      }
      // 有数据 表示编辑
      if (this.data && this.data.id) {
        for (const key in formData) {
          this.formData[key] = this.data[key];
        }
        this.originalCellPhone = this.data.cellphone;
        // 禁用用户信息
        this.formDisabled.cellphone = true;
        this.formDisabled.name = true;
        this.$nextTick(() => {
          this.$refs.tree &&
            this.$refs.tree.setCheckedKeys([...this.data.menuIdList]);
        });
      }
    },
    // 获取权限菜单数据
    async getMenuTree() {
      const res = await getMenuTree();
      this.menuTree = res || [];
    },
    // 重置表单
    resetForm() {
      this.formData = this.$deepClone(formData);
      this.$refs.form.resetFields();
    },
    //提交表单
    submitForm() {
      let keys = this.$refs.tree && this.$refs.tree.getCheckedKeys();
      this.formData.menuIdList = keys || null;
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        if (!this.formData.id && !this.formData.name) {
          this.$showError("请选择关联或创建用户");
          return;
        }
        if (this.formData.roleType == 1) {
          this.formData.menuIdList = [];
        }
        const fn = this.formData.id ? editEnterpriseUser : createEnterpriseUser;
        const res = await fn(this.formData);
        if (res) {
          this.$emit("update:visible", false);
          this.$showSuccess("提交成功");
          this.$emit("success");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.tree-box {
  border: 1px solid #f2f2f2;
  padding: 16px;
  border-radius: 5px;
  &.disabled {
    ::v-deep {
      .el-tree-node__content {
        background-color: #fff !important;
      }
      .expanded {
        pointer-events: none;
      }
      .el-tree-node__children {
        .el-tree-node {
          float: left;
        }
      }
    }
  }
}
</style>
