var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    on: {
      "open": _vm.handleOpen,
      "close": _vm.resetForm
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px",
      "label-position": "top"
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "label": "手机号：",
      "rules": [_vm.$formRules.required(), _vm.$formRules.tel, _vm.originalCellPhone !== _vm.formData.cellphone ? _vm.checkTel : {}],
      "prop": "cellphone"
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "11",
      "placeholder": "请输入",
      "disabled": !!_vm.formData.id && _vm.formDisabled.cellphone
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleInputClick('cellphone');
      }
    },
    model: {
      value: _vm.formData.cellphone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cellphone", $$v);
      },
      expression: "formData.cellphone"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticStyle: {
      "width": "260px"
    },
    attrs: {
      "label": "用户姓名：",
      "rules": [_vm.$formRules.required()],
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入",
      "disabled": !!_vm.formData.id && _vm.formDisabled.name
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleInputClick('name');
      }
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "用户角色：",
      "rules": [_vm.$formRules.required()],
      "prop": "roleType"
    }
  }, [_c('el-radio-group', {
    staticClass: "f-radio-oval",
    model: {
      value: _vm.formData.roleType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "roleType", $$v);
      },
      expression: "formData.roleType"
    }
  }, _vm._l(_vm.roleTypeList, function (item, index) {
    return _c('el-radio', {
      key: index,
      attrs: {
        "label": item.id,
        "border": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.formData.roleType == 2 ? _c('el-form-item', {
    attrs: {
      "label": "设置权限：",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', {
    staticClass: "tree-box",
    class: {
      disabled: _vm.$route.path === '/distribution/userManage/admin'
    }
  }, [_c('el-tree', {
    ref: "tree",
    attrs: {
      "default-expand-all": _vm.$route.path === '/distribution/userManage/admin',
      "data": _vm.menuTree,
      "show-checkbox": "",
      "node-key": "id",
      "expand-on-click-node": false,
      "check-on-click-node": "",
      "props": _vm.defaultProps
    }
  })], 1)]) : _vm._e()], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:visible', false);
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("保 存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }