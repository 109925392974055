<template>
  <div class="admin base-container">
    <div class="header-mol">
      <div class="info">
        <div class="title">管理员</div>
        <div class="summary">
          超级管理员可以使用运营平台所有功能，普通管理员只拥有指定功能权限
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showEditDialog(null)"
        >
          添加管理员
        </el-button>
      </div>
    </div>
    <div class="filter mt20">
      <el-input
        placeholder="名称、手机号"
        v-model="queryData.keyword"
        style="width: 320px;"
        class="mr20 f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        v-model="queryData.roleType"
        placeholder="角色"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in roleTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="姓名">
        <div slot-scope="scope">
          <p>{{ scope.row.name }}</p>
        </div>
      </el-table-column>
      <el-table-column label="电话">
        <div slot-scope="scope">
          <p>{{ scope.row.cellphone | formatPhone }}</p>
        </div>
      </el-table-column>
      <el-table-column label="角色">
        <div slot-scope="scope">
          <p>{{ roleType[scope.row.roleType] }}</p>
        </div>
      </el-table-column>
      <el-table-column label="注册时间">
        <div slot-scope="scope">
          <p>{{ scope.row.inDate }}</p>
        </div>
      </el-table-column>
      <el-table-column label="操作" width="120px">
        <template slot-scope="scope">
          <el-button type="text" @click="showEditDialog(scope.row)">
            编辑
          </el-button>
          <el-button
            type="text"
            class="red ml20"
            @click="deleteEnterpriseUser(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getEnterpriseUserList"
    ></f-pagination>
    <c-edit-dialog
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
      class="f-dialog"
      :data="activeItem"
      :title="activeItem?.id ? '编辑管理员' : '添加管理员'"
      width="700px"
      @success="getEnterpriseUserList"
    >
    </c-edit-dialog>
  </div>
</template>

<script>
import { getEnterpriseUserList, deleteEnterpriseUser } from "@/api/setting";
import CEditDialog from "./_components/edit";
export default {
  components: {
    CEditDialog
  },
  data() {
    return {
      list: [],
      roleTypeList: [
        {
          id: 1,
          name: "超级管理员"
        },
        {
          id: 2,
          name: "管理员"
        }
      ],
      roleType: {
        1: "超级管理员",
        2: "管理员"
      },
      total: 0, //列表总数
      queryData: {
        keyword: "",
        roleType: "", //角色类型
        pageNum: 1,
        pageSize: 10
      },
      activeItem: {}, // 当前操作的数据
      editDialogVisible: false //时候显示编辑对话框
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getEnterpriseUserList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getEnterpriseUserList();
    },
    async deleteEnterpriseUser(id) {
      //删除榜单规则
      this.$confirm("确定删除该管理员吗？", "删除管理员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteEnterpriseUser({ id });
          if (res) {
            this.$showSuccess("删除成功");
            this.getEnterpriseUserList();
          }
        })
        .catch(() => {});
    },
    handleSizeChange(num) {
      //显示条数改变
      this.queryData.pageSize = num;
      this.getEnterpriseUserList();
    },
    async getEnterpriseUserList(e) {
      //获取管理员列表
      if (e) {
        Object.assign(this.queryData, e);
      }
      const data = await getEnterpriseUserList(this.queryData);
      if (data) {
        this.list = data.list;
        this.total = data.total;
      }
    },
    //点击编辑
    showEditDialog(row) {
      this.activeItem = this.$deepClone(row);
      this.editDialogVisible = true;
    }
  }
};
</script>
<style scoped lang="scss">
.admin {
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    .tips {
      margin-top: 10px;
      color: #999;
    }
  }
}
</style>
